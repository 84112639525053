import "../styles/index.scss"

window.addEventListener('DOMContentLoaded', event => {
  const menuToggle = document.getElementById('menuToggle');
  menuToggle.addEventListener('click', event => {
    const menu = document.getElementById('menu');

    if (menu.classList.contains('hidden')) {
      menu.classList.remove('hidden');
    } else {
      menu.classList.add('hidden');
    }
  });
});
